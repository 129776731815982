import React from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { NotFound } from '../components/pages/not-found/not-found-component'

const NotFoundPage = () => (
  <Layout>

    <SEO
      title="404 - Not found"
      keyword={['404', 'error', 'not found', 'cooking', 'traveling', 'travel']}
    />

    <NotFound />

  </Layout>
)

export default NotFoundPage
