import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BigText = styled.h1`
  color: ${props => props.theme.colors.nori};
  font-family: ${props => props.theme.fonts.solway};
  font-size: 12rem;
  margin: 0;
`

export const Text = styled.h4`
  color: ${props => props.theme.colors.salmon};
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 2rem;
  margin: 0;
`;
